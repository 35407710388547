// ** Auth Endpoints
// const url replace baseurl remove /panel
const url = process.env.REACT_APP_BASEURL.replace('/panel', '')
export default {
  loginEndpoint: `${url}auth/login`,
  registerEndpoint: `${url}auth/register`,
  refreshEndpoint: `${url}auth/refresh`,
  logoutEndpoint: `${url}auth/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
